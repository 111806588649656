@import "../../../global-styles/variables";

.outerContainer {
  margin-bottom: 30px;
  width: 100%;
}

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: fit-content;
  align-items: center;

  @media screen and (max-width: $mobile-cutoff) {
    min-width: 0;
  }
}

.patientBlockContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: min-content;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(199, 199, 199);
}

.name {
  display: flex;
  padding-bottom: 0.8rem;
  width: max-content;

  @include mobile {
    width: auto;
  }
}

.buttonContainer {
  display: flex;

  @media screen and (min-width: $mobile-cutoff) {
    > * {
      margin-right: 1.5rem;
    }
    > *:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: $mobile-cutoff) {
    flex-direction: column;
    > *:first-child {
      margin-bottom: 1rem;
    }
  }
}

@media screen and (max-width: $mobile-cutoff) {
  .button {
    width: 8rem;
  }
}

.drCont {
  display: flex;

  @media screen and (max-width: $mobile-cutoff) {
    flex-direction: column;
    > p {
      margin-left: 1.5rem;
      font-weight: bold;
      color: rgb(54, 54, 54);
      margin-top: 0.3rem;
    }
  }
}

.drContTitle {
  display: flex;
  > img {
    margin-right: 0.5rem;
    opacity: 0.5;
    width: 1rem;
    height: 1.2rem;
  }

  @media screen and (min-width: $mobile-cutoff) {
    margin-right: 0.5rem;
  }
}

.newTicketButtonCont {
  position: relative;
  z-index: 1000;
}

.newTicketContainer {
  display: flex;
  flex-flow: column;
  padding: 1rem 2rem 1rem 2rem;
  position: absolute;
  width: 13rem;
  top: 3rem;
  left: 50%;
  background-color: white;
  box-shadow: 3px 3px 3px 0 rgb(190, 190, 190);
  box-sizing: border-box;
  z-index: 60;
  border: solid 1px var(--blue);
  border-radius: 10px;
  transform: translate(-150%, -30%);
}

.newTicketContainer > * {
  margin-bottom: 1rem;
}

.newTicketContainer > *:last-child {
  margin-bottom: 0;
}

@include mobile {
  .left {
    flex-shrink: 1;
  }
}

.adContainer {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
