.noTicketMsg {
  margin: 10rem auto 0 auto;
  font-size: 1.2rem;
  font-weight: 500;
}

.container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  margin-top: 50px;
}
