.outerContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.096);

  .container {
    height: 100%;
    width: 100%;
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    .content {
      width: 100%;
      height: 100%;
      margin-top: auto;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      background-color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
      border-radius: 6px;
      color: white;
      padding: 10px;
      box-sizing: border-box;
      display: flex;

      @media (hover: none) {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }

      .textContainer {
        height: fit-content;
        width: 100%;
        margin-top: auto;

        h1 {
          font-size: 1.5rem;
          font-weight: bold;
        }

        h2 {
          font-size: 1rem;
          font-weight: normal;
        }

        p {
          font-size: 0.8rem;
          font-weight: normal;
        }
      }
    }
  }
}
