@import "../../global-styles/variables";

.container {
  composes: block-after from "../../global-styles/block-visuals.module.css";
  --padding-horizontal: 2rem;
  background-color: white;
  width: 35rem;
  border-radius: 15px;
  padding: 0 var(--padding-horizontal) 1rem var(--padding-horizontal);
  box-sizing: border-box;
  position: relative;

  @include mobile {
    width: 100%;
  }
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomContainer {
  margin-top: 1rem;
  max-width: 100%;

  .note {
    margin-bottom: 1rem;
    color: gray;
  }
}

.title {
  border-bottom: solid 1px #e0e0e0;
  width: 100%;
  padding: 0.75rem var(--padding-horizontal) 0.5rem var(--padding-horizontal);
  margin-bottom: 1rem;
  transform: translateX(calc(-1 * var(--padding-horizontal)));
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex: 1;
  margin-top: 1rem;

  > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
